import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyledPopup,PopupWrapper} from './styles';
import { ImageUrl } from '../../utils/constants';
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next';
class ViewCommentModal extends Component{

  onClosePopup = ()=>{
    this.props.onClose();
  }

  hidePopup = (id, value)=>{
    this.props.changeEditDeletePopup(id, value);
    this.props.onClose();
  }

  render(){
    const { showModal, image } = this.props;
    return(
      <StyledPopup show={showModal} width={"1080px !important"}>
        <div className='closeIcon' onClick={()=>this.onClosePopup()}>
          <img src={ImageUrl+"/social-feed/closeWhite.png"} />
        </div>
        <PopupWrapper width={"100%"}>
          <div className='imageContainer' style={{width:"100%"}}>
            <img src={`${ImageUrl}/${image}`} alt={ 'placeholder'} />
          </div>
        </PopupWrapper>
      </StyledPopup>
    )
  }
}

ViewCommentModal.propTypes =  {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  feed: PropTypes.object.isRequired,
  description: PropTypes.string,
  taggedUsers: PropTypes.string,
  getCommentsForPost: PropTypes.func.isRequired,
  changeEditDeletePopup: PropTypes.func.isRequired,
  editAndDelete: PropTypes.bool.isRequired,
  openEditAndDelete: PropTypes.func.isRequired,
  profileImage: PropTypes.string,
  updationList: PropTypes.string,
  commentCount: PropTypes.number,
  history: PropTypes.object,
  enableTrigger: PropTypes.bool,
  onChange: PropTypes.func,
  handleFeed: PropTypes.func,
  handleModal: PropTypes.func.isRequired,
  feedNumber : PropTypes.number,
  data: PropTypes.array.isRequired,
  exemplifiesValues : PropTypes.array,
  showRecognition : PropTypes.func,
  value : PropTypes.string,
  id : PropTypes.number,
  image : PropTypes.string,
  feedNo : PropTypes.number,
  updateFeedsLike: PropTypes.func,
  editPost: PropTypes.func,
  storedFeeds: PropTypes.array,
  socialCallBack: PropTypes.func,
  handlePhotoPostModal: PropTypes.func,
  postReplyOnComment: PropTypes.func.isRequired,
  comments: PropTypes.array,
  postReply: PropTypes.func,
  replySuccess: PropTypes.string,
  inspirationPost: PropTypes.array,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  inspirationPost: state.social.inspirationPost
});

export default connect(mapStateToProps, null)((withTranslation())(ViewCommentModal));